import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
// import { FaMapLocationDot } from "react-icons/fa6";
// import { MdMail } from "react-icons/md";
// import { FaPhoneAlt } from "react-icons/fa";
import WhatsAppButton from "./Whatsapp";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const handlenavigate = () => {
    navigate("/products");
  };
  return (
    <>
      {location.pathname !== "/products" && (
        <div className="fixed point2 blink" onClick={handlenavigate}>
          <img
            src={require("../../assets/images/flash.png")}
            className="img-fluid priceicn2 float-right"
            alt="special price"
          />
        </div>
      )}
      <div className="fixed point">
        <WhatsAppButton name="GMR Crackers" phoneNumber="919843058048" />
      </div>
      <a href="https://maps.app.goo.gl/KHdyTe4PzvXNUAN9A">
        <div className="fixed point3" style={{ cursor: "pointer" }}>
          <img
            src={require("../../assets/images/googlelocation.png")}
            className="img-fluid priceicn float-left"
            alt="special price"
          />
        </div>
      </a>

      {/* <a href="https://www.instagram.com/amaranramesh1122000?igsh=aGE2dnJoZnU1M2g=">
        <div className="fixed point4" style={{ cursor: 'pointer' }}>
          <img src={require('../../assets/images/inst.png')} className='img-fluid priceicn float-left' alt='special price' />
        </div>
      </a> 
      <a href="https://www.facebook.com/profile.php?id=61556757217479&mibextid=ZbWKwL">
        <div className="fixed point5" style={{ cursor: 'pointer' }}>
          <img src={require('../../assets/images/fb.png')} className='img-fluid priceicn float-left' alt='special price' />
        </div>
      </a> 
      <a href="https://youtube.com/@rkamaran...6617?si=-oU5tWwgPmQbLxIn">
        <div className="fixed point6" style={{ cursor: 'pointer' }}>
          <img src={require('../../assets/images/you.jpg')} className='img-fluid priceicn float-left' alt='special price' />
        </div>
      </a> */}
      <>
        <div className="footer-bg foot-pad">
          <Container>
            <Row>
              <Col lg="12" className="border-top border-bottom py-4">
                <Row>
                  <Col lg="4" className="align-self-center py-3">
                    <div>
                      <div className="ft-details">
                        <h3 className="bold fw-bold"> Location</h3>
                        Sivakasi - 626189
                      </div>
                    </div>
                  </Col>
                  <Col lg="4" className="align-self-center py-3">
                    <div className="ft-logo mx-auto">
                      <img
                        src={require("../../assets/images/banner/logo.png")}
                        className="img-fluid"
                        alt="product name"
                      />
                    </div>
                  </Col>
                  <Col lg="4" className="align-self-center py-3">
                    <div>
                      <div className="ft-details">
                        <div>
                          <span className="fw-bold bold h3">Email</span> :
                          gmrcrackers@gmail.com
                        </div>
                        <h3 className="bold fw-bold"> Phone Number</h3>
                        <li>+91 9843058048</li>
                        <li>+91 7358866226</li>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg="12" className="py-3 mx-auto">
                <ul className="list-unstyled d-lg-flex justify-content-center d-xs-block d-sm-block">
                  <li className="mx-3">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="mx-3">
                    <Link to="/about">About</Link>
                  </li>
                  <li className="mx-3">
                    <Link to="/products">Products</Link>
                  </li>
                  <li className="mx-3">
                    <Link to="/safetytips">Safety Tips</Link>
                  </li>
                  <li className="mx-3">
                    <Link to="/contactus">Contact Us</Link>
                  </li>
                  <li className="mx-3">
                    <Link to="/chitsfunds">Chits Funds</Link>
                  </li>
                </ul>
              </Col>
              <Col lg="12" className="py-3 align-self-center">
                <div className="rules">
                  As per 2018 supreme court order, online sale of firecrackers
                  are not permitted! We value our customers and at the same
                  time, respect jurisdiction. We request you to add your
                  products to the cart and submit the required crackers through
                  the enquiry button. We will contact you within 24 hrs and
                  confirm the order through WhatsApp or phone call. Please add
                  and submit your enquiries and enjoy your Diwali with GMR
                  Crackers. Our License No.----. GMR Crackers as a company
                  following 100% legal & statutory compliances and all our
                  shops, go-downs are maintained as per the explosive acts. We
                  send the parcels through registered and legal transport
                  service providers as like every other major companies in
                  Sivakasi is doing so.
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
      <>
        <div className="mark-bg">
          <Container>
            <Row>
              <Col lg="12" className="py-3">
                <div className="text-center regular text-white">
                  {" "}
                  Copyright © 2024,. GMR Crackers. All rights reserved{" "}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    </>
  );
};

export default Footer;
