import React from "react";
import { Container, Nav, Navbar, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Marquee from "react-fast-marquee";
const AppBar = () => {
  return (
    <>
      <>
        <div className="mark-bg">
          <div className="d-lg-block d-none">
            <Container>
              <Row className="py-2">
                <Col
                  lg="4"
                  xs="4"
                  sm="4"
                  className="align-self-center d-lg-block d-none"
                >
                  <div className="d-flex align-items-center">
                    <div>
                      <img
                        src={require("../../assets/images/gpay.png")}
                        className="img-fluid gpay"
                        alt="gpay"
                      />
                    </div>
                    <div className="text-white">
                      <h5 className="bold mt-2 mx-2">9498402615</h5>
                    </div>
                  </div>
                </Col>
                <Col lg="8" className="align-self-center text-white">
                  <Marquee
                    pauseOnHover={true}
                    direction="left"
                    speed={50}
                    delay={9}
                  >
                    | Whatsapp : +91 9843058048 | Alter No : +91 7358866226 |
                    Email : gmrcrackers@gmail.com
                  </Marquee>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="d-lg-none d-block py-3">
            <Container>
              <Row>
                <Col xs="4" sm="4">
                  <a href="https://wa.me/919843058048?text=Hello GMR crackers">
                    <div className="mob-icon mx-auto">
                      <img
                        src={require("../../assets/images/whatsappicon.webp")}
                        className="img-fluid"
                        alt="product name"
                      />
                    </div>
                  </a>
                </Col>
                <Col xs="4" sm="4">
                  <a href="tel:+917358866226">
                    <div className="mob-icon mx-auto">
                      <img
                        src={require("../../assets/images/callicon.webp")}
                        className="img-fluid"
                        alt="product name"
                      />
                    </div>
                  </a>
                </Col>
                <Col xs="4" sm="4">
                  <a
                    href="https://maps.app.goo.gl/KHdyTe4PzvXNUAN9A"
                    alt="GMR crackers location"
                  >
                    <div className="mob-icon mx-auto">
                      <img
                        src={require("../../assets/images/googlelocation.png")}
                        className="img-fluid"
                        alt="product name"
                      />
                    </div>
                  </a>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
      <>
        <div className="navv-bg ">
          <Container className="py-3 z">
            <Row>
              <Col lg="3" md="12" xs="12">
                <Navbar.Brand href="/">
                  <div className="logo mx-auto">
                    <img
                      src={require("../../assets/images/banner/logo.png")}
                      className="img-fluid"
                      alt="product name"
                    />
                  </div>
                </Navbar.Brand>
              </Col>
              <Col lg="3" className="align-self-center d-lg-block d-none">
                <div className="d-flex justify-content-center text-black">
                  <div></div>
                  <div>
                    <h5 className="bold"> Address</h5>
                    <p className="regular">
                      Sivakasi to Alangulam Main Road, Singampatti,626124
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg="3" className="align-self-center d-lg-block d-none">
                <div className="d-flex justify-content-center text-black">
                  <div></div>
                  <div>
                    <h5 className="bold"> Branch Address</h5>
                    <p className="regular">
                      Rudhra Maya crackers Shop,
                      8A,Kannadapalayam,vellanur,avadi,chennai-62
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg="3" className="align-self-center d-lg-block d-none">
                <div className="d-flex justify-content-center text-black">
                  <div></div>
                  <div class>
                    <h5 className="bold"> Mobile Number</h5>
                    <div className="regular">
                      <li>+91 9843058048</li>
                      <li>+91 7358866226</li>
                      <li>+91 99768 58631</li>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>

      <>
        <Navbar expand="lg" className="navlinkContainer-bg">
          <Container>
            {/* <div className="mx-auto toicon">
              <Navbar.Toggle aria-controls="mx-auto text-center py-3" />
            </div> */}

            <Navbar.Collapse className="show">
              <Nav className="me-auto text-center py-3 ">
                <Nav.Link className="text-white" as={NavLink} exact to={"/"}>
                  Home
                </Nav.Link>
                <Nav.Link
                  className="text-white"
                  as={NavLink}
                  exact
                  to={"/about"}
                >
                  About
                </Nav.Link>
                <Nav.Link
                  className="text-white blink-highlight"
                  as={NavLink}
                  exact
                  to={"/products"}
                >
                  PriceList
                </Nav.Link>
                <Nav.Link
                  className="text-white "
                  as={NavLink}
                  exact
                  to={"/safetytips"}
                >
                  Safety Tips
                </Nav.Link>
                <Nav.Link
                  className="text-white"
                  as={NavLink}
                  exact
                  to={"/contact"}
                >
                  Contact
                </Nav.Link>
                <Nav.Link
                  className="text-white blink-highlight"
                  as={NavLink}
                  exact
                  to={"/chitsfunds"}
                >
                  Chit Funds
                </Nav.Link>
                {/* <Nav.Link as={NavLink} exact to={"/safetytips"} activeClassName="active">Safety Tips</Nav.Link>
              <Nav.Link as={NavLink} exact to={"/contactus"} activeClassName="active">Contact Us</Nav.Link> */}
              </Nav>
              {/* <Nav.Link as={NavLink} exact to={"/products"} activeClassName="active" className='text-center estimate'>Estimate Now !!!</Nav.Link> */}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    </>
  );
};

export default AppBar;
